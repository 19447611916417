


import Routers from './route/route'
function App() {
  return (
   
			<Routers />
	
  );
}

export default App;
