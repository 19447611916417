import React, { useEffect } from 'react';

const ApplePayButton = () => {
  useEffect(() => {
    // Check if Apple Pay is available on this device
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      const applePayButton = document.querySelector('apple-pay-button');
      if (applePayButton) {
        applePayButton.style.display = 'inline-block'; // Show the button if available
        applePayButton.addEventListener('click', onApplePayButtonClicked);
      }
    } else {
      console.log('Apple Pay is not available on this device.');
    }

    // Clean up by removing the event listener when the component unmounts
    return () => {
      const applePayButton = document.querySelector('apple-pay-button');
      if (applePayButton) {
        applePayButton.removeEventListener('click', onApplePayButtonClicked);
      }
    };
  }, []);

  // Function to handle Apple Pay Button Click
  const onApplePayButtonClicked = async () => {
    console.log('Apple Pay button clicked');
    
    if (!window.ApplePaySession) {
      console.error('Apple Pay is not supported in this browser.');
      return;
    }

    try {
      const request = {
        
        countryCode: 'US',
        currencyCode: 'USD',
        supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
        merchantCapabilities: ['supports3DS'],
        total: {
          label: 'Demo (Card is not charged)',
          type: 'final',
          amount: '1.99',
        },
        "lineItems": [
    {
        "label": "Bag Subtotal",
        "type": "final",
        "amount": "35.00"
    },
    {
        "label": "Free Shipping",
        "amount": "0.00",
        "type": "final"
    },
    {
        "label": "Estimated Tax",
        "amount": "3.06",
        "type": "final"
    }
]
      };

      const session = new window.ApplePaySession(3, request);

      console.log(session);
      alert(session)
      

      session.onmerchantvalidation = (event) => {
       console.log(event,"validation");
       alert(event)
       var URL = event.validationURL
       console.log(URL,"validation");
        event.complete({});
      };

      session.onpaymentmethodselected = (event) => {
        console.log(event,"payment");
        
        session.completePaymentMethodSelection({});
      };

      session.onpaymentauthorized = (event) => {
        console.log(event,"authorised");
     
        
        const result = { status: window.ApplePaySession.STATUS_SUCCESS };
        session.completePayment(result);
      };

      session.oncancel = () => {
        console.log('Payment process was canceled');
      };

      session.begin();
    } catch (e) {
      console.error('Payment failed:', e);
    }
  };

  return (
    <div>
      
      <apple-pay-button
        buttonstyle="black"
        type="order"
        locale="en-US"
        
      />
    </div>
  );
};

export default ApplePayButton;
