
import React, { useState } from 'react';
import { 
  TextField,
  Paper,
  Typography,
  Box,
  Container
} from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Googlepay from './GooglePay.jsx/googlepay';
import Applepay from './Applepay/Applepay';

const Index = () => {
 
  const srtipepromise =   loadStripe("pk_test_51QkJBsGkvkF6CKbP3cFynuph59rXhymCMi3C6O4YGJjpN7GCCYqYtJ16CziwYzwpycHKl5eNPTqXFVgUPgat3mCH00TVSKubY7")
  const [formData, setFormData] = useState({
    productName: '',
    price: '',
    address: '',
    Countrycode: '',
    state: '',
    zipCode: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  return (
    <Container maxWidth="sm" sx={{marginTop:'100px'}}>
      <Paper elevation={3} sx={{ p: 4, mt: 10, }}>
        <Typography variant="h5" gutterBottom align="center" sx={{ mb: 4 }}>
          Product Order Form
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              label="Product Name"
              name="productName"
              value={formData.productName}
              onChange={handleChange}
              fullWidth
              required
              variant="outlined"
            />

            <TextField
              label="Price"
              name="price"
              type="number"
              value={formData.price}
              onChange={handleChange}
              fullWidth
              required
              variant="outlined"
              InputProps={{
                startAdornment: '$'
              }}
            />

            <TextField
              label="Street Address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              fullWidth
              required
              variant="outlined"
            />

            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextField
                label="Countrycode"
                name="Countrycode"
                value={formData.Countrycode}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
              />

              <TextField
                label="State"
                name="state"
                value={formData.state}
                onChange={handleChange}
                fullWidth
                required
                variant="outlined"
              />
            </Box>

            <TextField
              label="ZIP Code"
              name="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
              required
              variant="outlined"
            />

          {/* <Googlepay formData={formData}/>  */}
          <Applepay/>
           {/* <Elements stripe={srtipepromise} >  
          //  
           </Elements> */}
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default Index;