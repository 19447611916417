
import GooglePayButton from "@google-pay/button-react";
import React, { useEffect, useState } from "react";
import { AlertTitle } from "@mui/material";
import Alert from '@mui/material/Alert';
const styles = {
  successMessage: {
    backgroundColor: '#28a745',  // Green color for success
    color: 'white',
    padding: '20px',
    borderRadius: '5px',
    marginBottom: '20px',
    textAlign: 'center',
  },
};

const Googlepay = (formData) => {
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const [price, setPrice] = useState("")
  const userdata=formData.formData;
  
  const initialShippingAddress =
  {
    address1: userdata.address,
    address2: "",
    address3: "",
    administrativeArea: "SP",
    countryCode: userdata.Countrycode,
    locality: userdata.state,
    name: "Sushma",
    postalCode: userdata.zipCode,
    sortingCode: ""
  }




  useEffect(() => {
    setPrice(userdata.price || '0.00');
  }, [userdata]);

 


  const handelload = (paymentRequest) => {
    console.log(paymentRequest);

    // Create a NEW object with editable: true.  Don't modify the original
    const shippingAddress = { ...initialShippingAddress, editable: true };

    paymentRequest.shippingAddress = shippingAddress;
  }


 


  return (
    <>
   
    
    {paymentSuccess ? (
     
       <Alert severity="success">
       <AlertTitle>Payment Successful!</AlertTitle>
       Your transaction was completed successfully.
     </Alert>
        
       
      ):(
    
   
    <GooglePayButton
    environment="TEST"
    paymentRequest={{
                 apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['MASTERCARD', 'VISA'],
          },
          tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
              "gateway": "stripe",
              "stripe:version": "2018-10-31",
              "stripe:publishableKey": "pk_test_51QkJBsGkvkF6CKbP3cFynuph59rXhymCMi3C6O4YGJjpN7GCCYqYtJ16CziwYzwpycHKl5eNPTqXFVgUPgat3mCH00TVSKubY7"
            },
          }
        },
      ],
      merchantInfo: {
        merchantId: '12345678901234567890',
        merchantName: 'kishore',
      },
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPriceLabel: 'Total',
        totalPrice: price,
        currencyCode: 'USD',
        countryCode: 'US',
      },
      shippingAddressRequired: true,
      callbackIntents: ['PAYMENT_AUTHORIZATION'],
    }}

    onLoadPaymentData={handelload}

    onPaymentAuthorized={paymentData => {
      console.log("Payment authorized:", paymentData);
     setPaymentSuccess(true)
    
      return { transactionState: 'SUCCESS' };
    }}

    onCancel={paymentData => {
      console.log("Payment is pending", paymentData);
    }}

    buttonColor="black"
    buttonType="plain"
    buttonSizeMode="fill"
    // style={{ gap: '2px', height: '40px', width: '485px' }}
   
  />)}
   </>
  )


}
export default Googlepay
