import Payment from '../component/index'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


export default function Routers() {
    return(
<Router>
 <Routes>
 <Route path='/' element={<Payment/>}></Route>
 </Routes>
    
</Router>
    )
}